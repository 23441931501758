<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img
                src="@/assets/images/pages/logo.png"
                alt=""
                style="padding-left: 15px;"
                class="mx-auto"
              />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white">
              <div class="p-12">
                <div class="vx-card__title mb-8">
                  <h1 class="mb-2">Mot de passe oublié</h1>
                  <p>Veuillez entrer votre nom d'utilisateur et nous vous enverrons le nouveau mot de passe par e-mail.</p>
                  <div v-show="error" class="mb-4 text-danger">
                    {{errorMsg}}
                  </div>
                  <div v-show="isSubmit" class="mt-4 text-success">
                   Si un compte existe pour <strong>{{ form.username }}</strong>, un mail contenant le nouveau mot de passe sera envoyé à l'adresse e-mail associée au compte
                  </div>
                </div>
                <div class="clearfix mt-8">
                  <vs-input
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      type="text"
                      name="nom d'utilisateur"
                      icon="icon icon-user"
                      icon-pack="feather"
                      label="Nom d'utilisateur"
                      v-model="form.username"
                      class="w-full no-icon-border"
                    />
                    <span class="text-danger text-sm">{{ errors.first("nom d'utilisateur") }}</span>
                </div>

                <div class="inline-flex mt-8">
                  <div>
                    <vs-button
                      class="px-4 w-full md:w-auto"
                      @click="byForgetPwd"
                      :disabled="!validateForm || loading"
                    >Réinitialiser</vs-button>
                  </div>
                  <div class="ml-2">
                    <clip-loader :loading="loading" color="primary"></clip-loader>
                  </div>
                </div>

                <vs-divider position="center" class="my-8"></vs-divider>Avez-vous déjà un compte ?
                <router-link class="ml-2" :to="{ name: 'Login'}">Connexion</router-link>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        from: "client",
        username: "",
      },
      email: "",
      loading: false,
      isSuccess: false,
      isSubmit: false,
      errorMsg: "",
      error: false
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.form.username != "";
    }
  },
  methods: {
    ...mapActions("auth", ["logout", "forgetPwd"]),
    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    async byForgetPwd() {
      if (!this.validateForm) return false;

      this.loading = true;
      this.isSubmit = false
      try {
        let res = await this.forgetPwd(this.form);
        this.email = res.data.email

        this.loading = false;
        this.isSuccess = true;
        this.isSubmit = true
        this.error = false;
      } catch (err) {
        this.loading = false;
        this.isSuccess = false;
        this.isSubmit = true
        this.error = true;

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {

            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  //this.errorMsg = "Aucun compte n’est associé à ce nom d’utilisateur"
                  break;
                case "sendMailError":
                  //this.errorMsg = item.message
                  break;

                default:
                  break;
              }
            })
          }
        }
      }
    }
  },
  components: {
    ClipLoader,
  }
};
</script>
